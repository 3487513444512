$circle-size: 170px;

.featured-solution {

  color: $primary;
  font-size: 1.8rem;

  .circle {
    margin: 0 auto;
    position: relative;
    width: $circle-size;
    height: $circle-size;
  }

  .arrow {
    width: $circle-size;
    height: $circle-size;
    border: 1px solid $primary;
    border-radius: 50%;
    position: absolute;
    transform: rotate(-35deg);
    transition: transform .5s ease;
  }
  .arrow:hover {
    transform: rotate(325deg);
  }
  .arrow:before {
    content: "";
    display: block;
    width: 10px;
    height: 20px;
    background: #fff;
    position: absolute;
    bottom: 0;
    top: 0;
    right: -6px;
    margin: auto;
  }
  .arrow:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border: solid $primary;
    border-width: 0 1px 1px 0;
    padding: 3px;
    top: $circle-size * 0.432; //top: calc(#{$circle-size} * 0.432);
    right: $circle-size * -0.012; //right: calc(#{$circle-size} * -0.012);
    transform: rotate(40deg);
  }

  .item {
    width: $circle-size / 1.14; //width: calc(#{$circle-size} - 12%);
    height: $circle-size / 1.14; //height: calc(#{$circle-size} - 12%);
    margin: $circle-size * 0.06; //margin: calc(#{$circle-size} * 0.06);
    font-size: 2.4rem;
    top:0;
    color: #fff;
    background-color: $primary;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /*
  @-webkit-keyframes rotating {
      from{
          -webkit-transform: rotate(0deg);
      }
      to{
          -webkit-transform: rotate(360deg);
      }
  }
  .rotating {
      -webkit-animation: rotating 2s linear infinite;
  }
   */


  .logo {
    width: 7.8rem;
    height: 2.5rem;
  }


}

.featured-solution.large {

  $circle-size: $circle-size * 1.30;

  .circle {
    width: $circle-size;
    height: $circle-size;
  }

  .arrow {
    width: $circle-size;
    height: $circle-size;
  }

  .arrow:after {
    top: $circle-size * 0.432;
    right: $circle-size * -0.012;
  }

  .item {
    width: $circle-size / 1.14;
    height: $circle-size / 1.14;
    margin: $circle-size * 0.06;
    font-size: 3rem;
  }

  .logo {
    width: 12rem;
    height: 4.2rem;
  }

}

