.map-pt {

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  position: relative;

  .map {
    max-width: 70rem;
    svg {
      user-select: none;
      .circle {
        fill: red;
        transition: all .25s ease-in-out;
        opacity: 0;
        &.visible {
          opacity: 1;
        }
      }
    }
    width: 100%;

  }

}
