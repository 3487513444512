.tag-cloud {

  .tag-line {
    width: 100%;
    float: left;

    .tag {
      &:first-child {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .tag {
    cursor: crosshair;
    float: left;
    color: $primary;
    user-select: none;
    padding: 1rem;

    $size-ref-width: 24.5rem; // 24.5 por 9.5rem
    $size-ref-height: $size-ref-width * 0.39;

    // Mobile
    //@include media-breakpoint-down(lg) {
      $multiplier: 0.50;
      font-size: 1.2rem;
      //width: $size-ref-width * $multiplier;
      width: auto;
      height: $size-ref-height * $multiplier;
    //}

    // Other breakpoints
    @include media-breakpoint-up(lg) {
      $multiplier: 0.70;
      font-size: 1.4rem;
      width: $size-ref-width * $multiplier;
      height: $size-ref-height * $multiplier;
    }

    @include media-breakpoint-up(xl) {
      $multiplier: 0.85;
      font-size: 1.6rem;
      width: $size-ref-width * $multiplier;
      height: $size-ref-height * $multiplier;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 1.8rem;
      width: $size-ref-width;
      height: $size-ref-height;
    }

    border: 1px solid $primary;
    margin: .9rem;

    transition: color, background-color 0.5s linear;
    -webkit-transition: color, background-color 0.5s linear;
    -moz-transition: color, background-color 0.5s linear;

    &:hover, &.active {
      color: #fff;
      background-color: $primary;
      font-family: 'sailecregular', sans-serif;
      font-weight: $font-weight-regular;
    }
  }

  .marquee {
    --gap: 0rem;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
  }

  .marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    min-width: 100%;
    gap: var(--gap);
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }

  .enable-animation .marquee__content {
    animation: scroll 20s linear infinite;
  }


}



