.testimonials {

  margin-top: 5rem;

  .testimonial {
    padding: 3rem;
  }

  .col-1 div {
    border: 1px solid red;
  }

  $photo-size: 14rem; // 16

  div.photo {
    margin: -8rem auto 3rem;
    border: 1px solid $primary;
    border-radius: 50%;
    padding: 0.8rem;
    background-color: #fff;
    width: $photo-size * 1.15;
    height: $photo-size * 1.15;
  }

  img.photo {
    background-color: unset;
    width: $photo-size;
    height: $photo-size;
    display: block;
  }

  .name {
    font-size: 1.8rem;
  }

  .quote-symbol {
    $size: 5rem; // 6.6
    margin: 2.5rem auto;
    width: $size;
    height: $size;
    border: 1px solid #fff;
    border-radius: 50%;
    background-size: $size / 2.35;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.991' height='25.441' viewBox='0 0 28.991 25.441'%3E%3Cpath id='Caminho_1109' data-name='Caminho 1109' d='M12.266,30.161V19.216H6.448a24.036,24.036,0,0,1,.247-3.4,10.091,10.091,0,0,1,.937-3.106,6.316,6.316,0,0,1,1.775-2.219,4.384,4.384,0,0,1,2.761-.838V4.72A9.57,9.57,0,0,0,7.335,5.9a10.091,10.091,0,0,0-3.4,3.156,14.136,14.136,0,0,0-1.972,4.585,23.328,23.328,0,0,0-.641,5.571V30.161Zm18.045,0V19.216H24.493a24.036,24.036,0,0,1,.247-3.4,10.091,10.091,0,0,1,.937-3.106,6.316,6.316,0,0,1,1.775-2.219,4.384,4.384,0,0,1,2.761-.838V4.72A9.57,9.57,0,0,0,25.381,5.9a10.091,10.091,0,0,0-3.4,3.156,14.136,14.136,0,0,0-1.972,4.585,23.328,23.328,0,0,0-.641,5.571V30.161Z' transform='translate(-1.32 -4.72)' fill='%23fff'/%3E%3C/svg%3E%0A");
  }

  /* Carrousel */
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    border: 1px solid $primary;
    border-radius: 50%;
    flex: 0 1 auto;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    margin: 5rem .8rem 3.5rem;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    opacity: 1;
  }

  .carousel-indicators .active {
    background-color: $primary;
  }

}
