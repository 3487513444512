@import "fonts";
@import "bootstrap";
@import "../blocks/blocks.style";

html {
  /*
  ## Base font size for the entire project ##
  All sizes should be defined in REMS whenever possible from now on.
   */
  font-size: 16px;
}

body {

  font-family: 'saileclight', sans-serif;
  font-weight: $font-weight-regular;
  -webkit-font-smoothing: antialiased;
  color: $body-color-dark;

  //&.footer-bottom {
  //  min-height: 100vh;
  //  display: flex;
  //  flex-direction: column;
  //}
  //overflow-x: hidden;
}

h1, h2, h3, h4 {
  color: $primary;
  font-weight: $font-weight-regular;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  margin-bottom: 1rem;
}

b, strong {
  //font-weight: $font-weight-bold;
  font-family: 'sailecregular', sans-serif;
  font-weight: $font-weight-regular;


}

a {
  text-decoration: none;
  -webkit-transition: color .2s, background-color .2s;
  transition: color .2s, background-color .2s
}

a:focus, a:active {
  outline: 0;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.hand {
  cursor: pointer;
}

.hyphens {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.no-hyphens {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

i, em {
    font-style: italic;
}

hr {
    border: 0;
    height: 2px;
    background: var(--bs-secondary);
    margin: 70px 0 30px 0;
}

pre, blockquote {
    //margin: 30px 0;
    border: 0;
    border-radius: 0;
    background-color: var(--bs-tertiary-bg);
}

pre code {
  font-size: 0.9em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}


code {
   background-color: var(--bs-tertiary-bg);
    padding: 0 5px;
}

blockquote {
    padding: 15px 20px;
    font-style: italic;
}

pre {
    padding: 20px;
}

div.logo {
  background-image: url("../resources/wize-logo.svg");
  background-repeat: no-repeat;
  width: 10rem;
  height: 3.3rem;
}

div.logo.white {
  filter: brightness(0) invert(1);
}




.alt-section {
  background-color: $primary;
  border-radius: var(--bs-border-radius-xl) !important;
  color: #fff;
  h1, h2, h3 {
    color: #fff;
  }
  h1 {
    font-size: 5.2rem;
    margin-bottom: 1.5rem;
  }
}

.bordered-section {
  color: $primary;
  border: 1px solid $primary;
  border-radius: var(--bs-border-radius-xl) !important;
}

.contrast-section {
  background-color: #F2F2F2;
  border-radius: var(--bs-border-radius-xl) !important;
  padding: 3.5rem;
}

.footer {
  font-size: 1.8rem;
  color: #fff;
  .logo {
    width: 19rem;
    height: 6.2rem;
  }
  small {
    font-size: 1.4rem;
  }
  .ssk-group a.ssk-icon {
    margin-right: 1rem;
    color: #fff !important;
  }
}

.bg-video {
  background: $primary;
  border-radius: var(--bs-border-radius-xl);
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -999;
  video {
    touch-action: none !important;
    border-radius: var(--bs-border-radius-xl);
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
  }
}

p.has-small-font-size {
  font-size: 1rem !important;
}

.has-medium-font-size {
  font-size: 1.8rem !important;
}

p.has-large-font-size {
  //font-size: 3rem !important;
  font-size: 2.5rem !important;
  line-height: 1.4;
}

p.has-wize-primary-color {
  color: $primary;
}

.has-wize-gray-color {
  color: $body-color-dark;
}

p.has-wize-black-color {
  color: #000;
}

p.has-wize-white-color {
  color: #fff;
}

/*
NAV BAR
 */
nav.navbar {
  font-family: 'sailecregular', sans-serif;
  font-weight: $font-weight-regular;
  li {
    margin: 0 1rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.3rem;
      margin: 0 2rem;
      &:last-of-type {
        margin:0;
      }
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.4rem;
      margin: 0 2.5rem;
      &:last-of-type {
        margin:0;
      }
    }
    a {
      padding-bottom: 1.5rem;
      @include media-breakpoint-up(lg) {
        padding-bottom: var(--bs-navbar-brand-padding-y);
      }
    }
  }
  a.navbar-brand {
    margin-top: -0.9rem;
  }
  button.navbar-toggler {
    border: 0;
    &:focus {
      box-shadow: none;
    }
  }
  .ssk-group {
    margin-left: 3rem;
    a.ssk-icon {
      margin-right: 2rem;
      color: rgba(0, 0, 0, 0.65);
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
      &:hover {
        color: $primary;
      }
    }
  }

  .nav-link:hover, .nav-link:focus {
    color: $primary;
  }


}

.page.navigation {
  font-size: 1.8rem;
  line-height: 1;
  margin-top: 7rem;
  margin-bottom: 7.5rem;
  a:not(.btn) {
    display: flex;
    color: $body-color-dark;
  }
  a.btn {
    width: 12rem;
  }
}

.arrow-link {
  vertical-align: middle;
  display: inline-block;
  width: 2.6rem;
  height: 1.8rem;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25.999' height='17.308' viewBox='0 0 25.999 17.308'%3E%3Cg id='Grupo_1517' data-name='Grupo 1517' transform='translate(1094.105 4130.25) rotate(180)'%3E%3Cpath id='Caminho_288' data-name='Caminho 288' d='M605.225,206.844l7.644,7.106-7.644,7.1,1.444,1.55,9.307-8.653-9.307-8.656Z' transform='translate(478.129 3907.648)' fill='none'/%3E%3Cg id='Grupo_965' data-name='Grupo 965' transform='translate(1068.105 4113.435)'%3E%3Cline id='Linha_39' data-name='Linha 39' x2='23.704' transform='translate(0 8.161)' fill='none' stroke='%23707070' stroke-miterlimit='10' stroke-width='1'/%3E%3Cline id='Linha_66' data-name='Linha 66' x1='8.161' y1='8.161' transform='translate(15.543)' fill='none' stroke='%23707070' stroke-width='1'/%3E%3Cline id='Linha_67' data-name='Linha 67' x1='8.161' y1='8.161' transform='translate(23.704 8.161) rotate(90)' fill='none' stroke='%23707070' stroke-width='1'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");

  &.left {
    margin-right: 1.5rem;
  }
  &.right {
    transform: rotate(180deg);
    margin-left: 1.5rem;
  }
}

/*
.contact-form-section .status {
  p {
    margin-bottom: 0;
    span {
      font-weight: $font-weight-regular;
    }
  }
}
*/


.wp-block-image.size-full {
  text-align: center;
}

table {
  margin-bottom: .85rem;
}

.wp-block-media-text {
  .wp-block-media-text__content {
    padding: 0;
  }
  figure.wp-block-media-text__media {
    max-width: 250px;
    margin: 0 auto 1rem auto;
  }
  @media (min-width: 599px) {
    figure.wp-block-media-text__media {
      max-width: unset;
      margin: 0 1.5rem 1rem 0;
    }
  }
}


.wp-block-table td, .wp-block-table th {
  font-size: 0.9rem;
  padding: 0.5em;
}

.wp-block-image figure:not(.alignfull):not(.alignwide) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.wp-block-image .is-style-rounded img,
.wp-block-image.is-style-circle-mask img,
.wp-block-image.is-style-rounded img {
  border-radius: var(--bs-border-radius-xl) !important;
}

.aligncenter {
  text-align: center;
}

img.aligncenter {
  margin-left:auto;
  margin-right:auto;
  display:block;
}

//.wp-block-buttons {
//  a {
//    margin: 0.5rem auto 2rem auto;
//    padding: .5rem 1rem;
//    color: #fff;
//    text-transform: uppercase;
//    border: unset;
//    border-radius: 0 !important;
//  }
//  .has-ptcook-primary-background-color {
//    background-color: $callout-evora;
//  }
//  .has-ptcook-lisbon-background-color {
//    background-color: $callout-lisbon;
//  }
//  @include media-breakpoint-up(sm) {
//    a::after {
//      color: #fff;
//      font-family: 'Font Awesome 5 Pro';
//      content: "\f178";
//      speak: none;
//      display: block;
//      font-weight: 400;
//      float: right;
//      padding-left: .5rem;
//    }
//  }
//  @media print {
//    display: none !important;
//  }
//}
//

.clear {
  clear: both;
}

hr.heading-hr-small {
  width: 7rem;
  margin: 2.5rem auto 3rem auto;
  background-color: $primary;
  opacity: 1;
  height: 1px;
  &.inverted {
    background-color: #fff;
  }
}

/* Arrows */
.arrowx {
  opacity: 1;
  border: 0;
  height: unset;
  background: unset;
  margin: 0;
  &.aligncenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  --animation-state: running;
}

.arrowx::before {
  display: block;
  background-image: linear-gradient($primary, $primary);
  background-size: 99.6% 1px;
  background-repeat: no-repeat;
  width: 22rem;
  height: 2.85rem;
  line-height:2.85rem;
  font-size:1rem;
  position: relative;
  clear: both;
  animation: backandforth 3s ease 0.8s;
  animation-iteration-count:infinite;
  animation-play-state: var(--animation-state);
}

.arrowx.small::before {
  width: 10rem;
}

@keyframes backandforth {
  0% {
    left: 0;
  }
  50% {
    left: 3rem;
  }
  100% {
    left: 0;
  }
}

.arrowx.right::before {
  text-align: right;
  margin-right: 3rem;
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214.707%22%20height%3D%2228.707%22%20viewBox%3D%220%200%2014.707%2028.707%22%3E%3Cg%20id%3D%22Grupo_1616%22%20data-name%3D%22Grupo%201616%22%20transform%3D%22translate%28-328.228%20-1547.147%29%22%3E%3Cline%20id%3D%22Linha_66%22%20data-name%3D%22Linha%2066%22%20x1%3D%2214%22%20y1%3D%2214%22%20transform%3D%22translate%28328.582%201547.5%29%22%20fill%3D%22none%22%20stroke%3D%22%231354fc%22%20stroke-width%3D%221%22%2F%3E%3Cline%20id%3D%22Linha_67%22%20data-name%3D%22Linha%2067%22%20x1%3D%2214%22%20y1%3D%2214%22%20transform%3D%22translate%28342.582%201561.5%29%20rotate%2890%29%22%20fill%3D%22none%22%20stroke%3D%22%231354fc%22%20stroke-width%3D%221%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: left center;
}

.arrowx.left::before {
  margin-left: 3rem;
  text-align: left;
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214.707%22%20height%3D%2228.707%22%20viewBox%3D%220%200%2014.707%2028.707%22%3E%0A%20%20%3Cg%20id%3D%22Grupo_1616%22%20data-name%3D%22Grupo%201616%22%20transform%3D%22translate%280.354%200.354%29%22%3E%0A%20%20%20%20%3Cline%20id%3D%22Linha_66%22%20data-name%3D%22Linha%2066%22%20y1%3D%2214%22%20x2%3D%2214%22%20fill%3D%22none%22%20stroke%3D%22%231354fc%22%20stroke-width%3D%221%22%2F%3E%0A%20%20%20%20%3Cline%20id%3D%22Linha_67%22%20data-name%3D%22Linha%2067%22%20x1%3D%2214%22%20y2%3D%2214%22%20transform%3D%22translate%2814%2014%29%20rotate%2890%29%22%20fill%3D%22none%22%20stroke%3D%22%231354fc%22%20stroke-width%3D%221%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
  background-position: right center;

}
