.circle-solutions {

  overflow: hidden;

  --pcircle-size: 320;
  @include media-breakpoint-up(md) {
    --pcircle-size: 480;
  }

  --pcircle-ring: 20;
  float: left;

  .ring {
    float: left;
    width: calc((var(--pcircle-size) + var(--pcircle-ring)) * 1px);
    height: calc((var(--pcircle-size) + var(--pcircle-ring)) * 1px);
    border: 1px solid $primary;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: inset 0 0 0 calc(var(--pcircle-ring) / 2 * 1px) #fff;
  }

  .pcircle {
    float: left;

    /*width: calc((var(--pcircle-size) + 2 + var(--pcircle-padding) * 2) * 1px);
    height: calc((var(--pcircle-size) + 2 + var(--pcircle-padding) * 2) * 1px);
    padding: calc(var(--pcircle-padding) * 1px);*/
    //border: 1px solid $primary;
    border-radius: 50%;
    width: calc(var(--pcircle-size) * 1px);
    height: calc(var(--pcircle-size) * 1px);
    display: flex;
    flex-flow: row wrap;
    transition: transform 1s ease;
    cursor: pointer;
    color: $primary;
    background-image: url("../resources/bola.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: calc(var(--pcircle-size) * 1px);
  }

  .pcircle div {
    height: calc(var(--pcircle-size) / 2 * 1px);
    width: calc(var(--pcircle-size) / 2 * 1px);
    color: #fff;
  }

  /* Debug hover */
  /*.pcircle div:hover {
    filter: brightness(85%);
    background-color: rgba(255, 0, 0, 0.2);
  }*/

  .pcircle div.c1 {
    //background-color: firebrick;
    border-radius: calc((var(--pcircle-size) / 2) * 1px) 0 0 0;
  }

  .pcircle div.c2 {
    //background-color: forestgreen;
    border-radius: 0 calc(var(--pcircle-size) / 2 * 1px) 0 0;
  }

  .pcircle div.c3 {
    //background-color: orange;
    border-radius: 0 0 0 calc(var(--pcircle-size) / 2 * 1px);
  }

  .pcircle div.c4 {
    //background-color: dodgerblue;
    border-radius: 0 0 calc(var(--pcircle-size) / 2 * 1px) 0;
  }

  .pcircle-info {
    position: relative;
    width: calc(var(--pcircle-size) * 0.09 * 1rem);
    height: 0;
    overflow: hidden;
    transition: height .6s ease;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;

    > div {
      font-size: 1.8rem;
      width: 100%;
      padding: 4rem;
      background-color: $primary;
      border-radius: 1rem;

      color: #fff;
      h3 {
        color: #fff;
      }

      display: none;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 2rem solid transparent;
      border-right: 2rem solid transparent;
      border-top: 2rem solid $primary;
      position: absolute;
      right: 50%;
      margin-right: -20px;
      bottom: 0;
      z-index: -999;
    }

  }

  .info-wrapper {
    width: 100%;
    height: 100%;
  }

  .center-text {
    text-align: center;
    position: absolute;
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    color: $primary;
  }

  .bg-video {
    border-radius: 50%;
    video {
      border-radius: 50%;
    }
  }
}
