.alt-section {
  transition: background-color 0.5s ease-in-out;
  &.success {
    background-color: #51ad00;
  }
  &.error {
    background-color: #ff0000;
  }
}

.contact-form {

  form > div {
    margin-bottom: 2.8rem;
  }

  &.light {
    .form-control {
      border-color: $primary;
    }

  }

  .contact-form-section {
    .status {
      p {
        font-size: 1.8rem;
        span {
          font-size: 4rem;
        }
      }
    }
  }

}
