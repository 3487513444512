.container-page-header {

  .content {
    @include media-breakpoint-up(lg) {
      min-height: 25rem;
    }
    padding-top: 2rem;
    padding-bottom: 2rem;

    h1, h2, p {
      color: #fff;
    }

    h1 {
      font-size: 1.8rem;
    }

    p {
      font-size: 2.4rem;
      line-height: 1.5;
    }

    &.front-page {
      @include media-breakpoint-up(lg) {
        min-height: 50rem;
      }
      p {
        font-size: 4.5rem;
        @include media-breakpoint-up(lg) {
          font-size: 5.2rem;
        }
        line-height: 1.2;
      }
    }
  }

  img.featured {
    width: 100%;
    max-width: 18rem;
    @include media-breakpoint-up(lg) {
      max-width: 38rem;
    }
  }

}

