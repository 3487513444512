@font-face {
  font-family: 'saileclight';
  src: url('../resources/fonts/sailec-light-webfont.woff2') format('woff2'), url('../resources/fonts/sailec-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'sailecregular';
  src: url('../resources/fonts/sailec-regular-webfont.woff2') format('woff2'), url('../resources/fonts/sailec-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
