.callout {
  $icon-size: 6.6rem;
  border: 1px solid $primary;
  height: 100%;

  .wrapper {
    width: 100%;
    height: 100%;
    &.has-icon {
      height: calc(100% - #{$icon-size});
    }
  }

  .contents {
    width: 100%;
    padding: 4rem;
    p:last-of-type, h1:last-of-type, h2:last-of-type {
      margin-bottom: 0;
    }
  }

  .icon {
    margin: -3.3rem auto 0 auto;
    background-color: $primary;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 55%;
    border-radius: 50%;
    width: $icon-size;
    height: $icon-size;
  }

}
